<template>
    <v-container fluid>
        <v-form @submit.prevent="Calculate">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('calculator_cost') }}</v-subheader>
                            </v-col>
                        </v-row>

                        <v-subheader class="text-h6">{{ $t('where_from_delivery') }}</v-subheader>
                        <v-container fluid>
                            <v-row>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="countryFrom" rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete v-model="countryFrom" :items="countryItemsFrom"
                                                        :error="!valid" :error-messages="errors"
                                                        item-text="name" item-value="uuid"
                                                        :loading="loadingCountriesFrom" :disabled="loading"
                                                        :label="$t('country')" @click:clear="clearFromCountries"

                                                        outlined
                                                        rounded
                                                        dense
                                                        color="primary" return-object clearable >
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6" >
                                    <ValidationProvider  ref="cityFrom" rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete v-model="cityFrom" :items="cityItemsFrom"
                                                        :error="!valid" :error-messages="errors"
                                                        item-text="name" item-value="uuid"
                                                        :loading="loadingCitiesFrom" :disabled="loading || !countryFrom"


                                                        :label="$t('city')" @click:clear="clearFromCities"
                                                        outlined
                                                        rounded
                                                        dense
                                                        color="primary" return-object clearable autocomplete="off">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-subheader class="text-h6">{{ $t('where_to_delivery') }}</v-subheader>
                        <v-container fluid>
                            <v-row>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="countryTo" rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete v-model="countryTo" :items="countryItemsTo"
                                                        :error="!valid" :error-messages="errors"
                                                        item-text="name" item-value="uuid"
                                                        :loading="loadingCountriesTo" :disabled="loading"
                                                        :label="$t('country')" @click:clear="clearToCountries"

                                                        outlined
                                                        rounded
                                                        dense
                                                        color="primary" return-object clearable >
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6" >
                                    <ValidationProvider  ref="cityTo" rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete v-model="cityTo" :items="cityItemsTo"
                                                        :error="!valid" :error-messages="errors"
                                                        item-text="name" item-value="uuid"
                                                        :loading="loadingCitiesTo" :disabled="loading || !countryTo"


                                                        :label="$t('city')" @click:clear="clearToCities"
                                                        outlined
                                                        rounded
                                                        dense
                                                        color="primary" return-object clearable autocomplete="off">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-subheader class="text-h6">{{ $t('weight') }}</v-subheader>
                        <v-container fluid>
                            <v-row>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="weight" rules="required|max:30"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="weight" type="number"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('weight')" color="primary"
                                                      outlined
                                                      rounded
                                                      dense
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    {{ $t('weight_text') }}
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-divider class="mt-2"></v-divider>
                        <v-subheader class="text-h6 my-0 cursor-pointer" @click="volumetricWeight()">
                            {{ $t('volumetric_weight') }}
                            <v-spacer></v-spacer>
                            <v-icon
                                large

                                v-text = "volumetric_weight ? 'mdi-chevron-down' : 'mdi-chevron-right'"
                            >

                            </v-icon>

                        </v-subheader>

                        <v-row v-if="volumetric_weight">
                            <v-col class="pt-0 d-flex flex-sm-grow-0 justify-center"  cols="12" sm="4">
                                <v-img class="py-0 my-0 mr-3"
                                    max-width="150"
                                    width="150"
                                    :src="package_type_img"
                                ></v-img>
                                <ValidationProvider ref="package_type" rules="required" v-slot="{ errors, valid }">
                                    <v-radio-group class="pt-2"
                                        :error="!valid" :error-messages="errors"
                                        :disabled="loading"
                                        v-model="package_type">
                                        <v-radio
                                            @click="clearFromDimensions('img/package_type_0.png')"
                                            :label="$t('box')"
                                            value="box"
                                        ></v-radio>

                                        <v-radio
                                            @click="clearFromDimensions('img/package_type_1.png')"
                                            :label="$t('roll')"
                                            value="roll"
                                        ></v-radio>

                                        <v-radio
                                            @click="clearFromDimensions('img/package_type_2.png')"
                                            :label="$t('tube')"
                                            value="tube"
                                        ></v-radio>
                                    </v-radio-group>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="8" v-if="package_type === 'box'">
                                <v-subheader class="text-h6 mb-1">{{ $t('dimension_title_1') }}</v-subheader>
                                <v-row>
                                    <v-col class="py-1" cols="12" sm="3">
                                        <ValidationProvider ref="length_0" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="length_0" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('length_a')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-1"  cols="12" sm="3">
                                        <ValidationProvider ref="width_0" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="width_0" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('width_b')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-1"  cols="12" sm="3">
                                        <ValidationProvider ref="height_0" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="height_0" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('height_h')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>


                            </v-col>


                            <v-col cols="12" sm="8" v-if="package_type === 'roll'">
                                <v-subheader class="text-h6 mb-1">{{ $t('dimension_title_2') }}</v-subheader>
                                <v-row>
                                    <v-col class="py-1" cols="12" sm="3">
                                        <ValidationProvider ref="length_1" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="length_1" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('length_h')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-1"  cols="12" sm="3">
                                        <ValidationProvider ref="diameter_1" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="diameter_1" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('diameter_a')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>


                            </v-col>


                            <v-col cols="12" sm="8" v-if="package_type === 'tube'">
                                <v-subheader class="text-h6 mb-1">{{ $t('dimension_title_3') }}</v-subheader>
                                <v-row>
                                    <v-col class="py-1" cols="12" sm="3">
                                        <ValidationProvider ref="length_2" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="length_2" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('length_a')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-1"  cols="12" sm="3">
                                        <ValidationProvider ref="height_2" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="height_2" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('height_h')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-1"  cols="12" sm="3">
                                        <ValidationProvider ref="median_2" rules="required|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="median_2" type="number"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('median_m')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>


                            </v-col>

                        </v-row>


                        <v-divider class="pt-4"></v-divider>



                        <v-data-iterator
                            :items="calculatorItems"
                            :items-per-page.sync="itemsPerPage"
                            hide-default-footer
                            v-if="calculator_show"
                        >


                            <template v-slot:default="props">
                                <v-row justify="center">
                                    <v-col
                                        v-for="item in props.items"
                                        :key="item.name"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                    >
                                        <v-card>
                                            <v-card-title class="subheading font-weight-bold">
                                                {{ item.name }}
                                            </v-card-title>

                                            <v-divider></v-divider>

                                            <v-list dense>
                                                <v-list-item>
                                                    <v-list-item-content>{{ $t('weight')}}:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{ item.weight }} {{ $t('kg')}}
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content>{{ $t('price_kg')}}:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{ item.price }} KZT
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content>{{ $t('total')}}:</v-list-item-content>
                                                    <v-list-item-content class="align-end">
                                                        {{ item.sum }} KZT
                                                    </v-list-item-content>
                                                </v-list-item>


                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>


                        </v-data-iterator>


                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('calculate') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce";



    export default {
        name: 'CalculatorForm',
        components: {
            ValidationProvider,
            ValidationObserver,

        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                tab: 0,
                loading: false,
                language: null,
                type_delivery: null,
                weight: null,
                countryFrom: null,
                countryItemsFrom: [],
                cityFrom: null,
                cityItemsFrom: [],
                loadingCitiesFrom: false,
                loadingCountriesFrom: false,
                countryTo: null,
                countryItemsTo: [],
                cityTo: null,
                cityItemsTo: [],
                loadingCitiesTo: false,
                loadingCountriesTo: false,
                volumetric_weight: false,
                package_type_img: "",
                package_type: "",
                length_0: '',
                height_0: '',
                width_0: '',
                length_1: '',
                diameter_1: '',
                length_2: '',
                height_2: '',
                median_2: '',
                calculator_show: false,
                itemsPerPage: 2,
                calculatorItems: [
                    {
                        name: 'Frozen Yogurt',
                        zone: 159,
                        weight: 6.0,
                        price: 24,
                        sum: 4.0,

                    },
                    {
                        name: 'Ice cream sandwich',
                        zone: 159,
                        weight: 6.0,
                        price: 24,
                        sum: 4.0,
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        watch: {
            countryFrom: debounce(function (val) {
                this.getFromCities(val)
            }, 500),
            countryTo: debounce(function (val) {
                this.getToCities(val)
            }, 500),
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getCountries()
        },
        methods: {

            volumetricWeight(){
                if(this.volumetric_weight){
                    this.volumetric_weight = 0;
                    this.package_type_img = ""
                    this.package_type = ""
                }
                else{
                    this.volumetric_weight = 1
                    this.package_type_img = "img/package_type_0.png"
                    this.package_type = "box"
                }
            },

            clearFromDimensions(img){
                this.package_type_img = img
                this.length_0 = ''
                this.height_0 = ''
                this.width_0 = ''
                this.length_1 = ''
                this.diameter_1 = ''
                this.length_2 = ''
                this.height_2 = ''
                this.median_2 = ''
            },
            clearFromCountries() {
                this.countryFrom = null
                this.cityFrom = null
                this.cityItemsFrom = []
            },
            clearFromCities() {
                this.cityFrom = null
            },
            clearToCountries() {
                this.countryTo = null
                this.cityTo = null
                this.cityItemsTo = []
            },
            clearToCities() {
                this.cityTo = null
            },
            async setLanguage(val) {
                this.language = this.languages[val]
            },
            async getFromCities(str) {
                if (str) {
                    this.loadingCitiesFrom = true
                    let params = {};
                    params.filter = 'search';
                    if (this.countryFrom) {
                        if (this.countryFrom.uuid) {
                            params.country = this.countryFrom.uuid;
                        } else {
                            params.country = this.countryFrom;
                        }
                    }
                    if (this.lang) {
                        params.language = this.lang
                    }
                    await this.$http
                        .get("courier/city", {
                            params: params,
                        })
                        .then(res => {

                            this.cityItemsFrom = res.body.data


                        })
                        .catch(err => {
                            this.cityItemsFrom = []
                            this.$toastr.error(this.$t('failed_to_get_list_cities'))
                        })
                        .finally(end => {
                            this.loadingCitiesFrom = false
                        })
                }
            },
            async getToCities(str) {
                if (str) {
                    this.loadingCitiesTo = true
                    let params = {};
                    params.filter = 'search';
                    if (this.countryTo) {
                        if (this.countryTo.uuid) {
                            params.country = this.countryTo.uuid;
                        } else {
                            params.country = this.countryTo;
                        }
                    }
                    if (this.lang) {
                        params.language = this.lang
                    }
                    await this.$http
                        .get("account/city", {
                            params: params,
                        })
                        .then(res => {

                            this.cityItemsTo = res.body.data


                        })
                        .catch(err => {
                            this.cityItemsTo = []
                            this.$toastr.error(this.$t('failed_to_get_list_cities'))
                        })
                        .finally(end => {
                            this.loadingCitiesTo = false
                        })
                }
            },
            async getCountries() {
                this.loadingCountries = true
                let params = {};
                params.filter = 'search';
                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("account/country", {
                        params: params,
                    })
                    .then(res => {
                        this.countryItemsTo = res.body.data
                        this.countryItemsFrom = res.body.data
                    })
                    .catch(err => {
                        this.countryItemsTo = []
                        this.countryItemsFrom = []
                        this.$toastr.error(this.$t('failed_to_get_list_countries'))
                    })
                    .finally(end => {
                        this.loadingCountries = false
                    })
            },


            async Calculate() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }


                if (this.type_delivery) {
                    formData.append('type_delivery', this.type_delivery)
                }

                if (this.package_type) {
                    formData.append('package_type', this.package_type)
                }
                if (this.weight) {
                    formData.append('weight', this.weight)
                }


                if (this.length_0) {
                    formData.append('length_0', this.length_0)
                }
                if (this.height_0) {
                    formData.append('height_0', this.height_0)
                }
                if (this.width_0) {
                    formData.append('width_0', this.width_0)
                }
                if (this.length_1) {
                    formData.append('length_1', this.length_1)
                }
                if (this.diameter_1) {
                    formData.append('diameter_1', this.diameter_1)
                }
                if (this.length_2) {
                    formData.append('length_2', this.length_2)
                }
                if (this.height_2) {
                    formData.append('height_2', this.height_2)
                }
                if (this.median_2) {
                    formData.append('median_2', this.median_2)
                }



                if (this.countryFrom) {
                    if (this.countryFrom.uuid) {
                        formData.append('country_from', this.countryFrom.uuid)
                    } else {
                        formData.append('country_from', this.countryFrom)
                    }
                }
                if (this.cityFrom) {
                    if (this.cityFrom.uuid) {
                        formData.append('city_from', this.cityFrom.uuid)
                    } else {
                        formData.append('city_from', this.cityFrom)
                    }
                }

                if (this.countryTo) {
                    if (this.countryTo.uuid) {
                        formData.append('country_to', this.countryTo.uuid)
                    } else {
                        formData.append('country_to', this.countryTo)
                    }
                }
                if (this.cityTo) {
                    if (this.cityTo.uuid) {
                        formData.append('city_to', this.cityTo.uuid)
                    } else {
                        formData.append('city_to', this.cityTo)
                    }
                }



                // Add
                await this.$http
                    .post('account/calculator', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {



                        if (res && res.body && res.body.data) {
                            this.$toastr.success(this.$t('calculation_cost_delivery_has_been_completed'))
                            this.calculatorItems = res.body.data
                            this.calculator_show = true
                        }
                        else {
                            this.$toastr.error(this.$t('not_calculate_shipping_cost'))
                        }

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('not_calculate_shipping_cost'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            }
        }
    }
</script>
